.productCard {
  --proportion: var(--image-proportion-landscape);

  position: relative;

  &.viewMini {
    max-width: 100%;
  }
}

.box {
  position: absolute;
  top: var(--space-l-neg);
  right: var(--space-m-neg);
  bottom: var(--space-l-neg);
  left: var(--space-m-neg);
  z-index: 3;

  display: block;
  min-height: 100%;

  border: 1px solid transparent;

  border-radius: 5px 5px 0 0;
  box-shadow: var(--shadow-popup);

  visibility: hidden;
  opacity: 0;

  transition: opacity 0.2s ease-in-out;

  &:before {
    position: absolute;
    right: 0;
    left: 0;
    z-index: 2;

    content: '';
  }
}

.container {
  position: relative;
  z-index: 2;

  height: inherit;
}

.additionalInfo {
  position: absolute;
  top: 100%;
  right: var(--space-m-neg);
  left: var(--space-m-neg);

  display: block;
  padding: 0 var(--space-m) var(--space-l);

  background: var(--color-white);
  border: 1px solid transparent;
  border-radius: 0 0 5px 5px;
  box-shadow: var(--shadow-popup);
  visibility: hidden;
  opacity: 0;

  transition: opacity 0.2s ease-in-out;

  &:before {
    position: absolute;
    top: var(--space-n-neg);
    right: 0;
    left: 0;
    z-index: 2;

    height: var(--space-n);

    background: var(--color-white);

    content: '';
  }
}

.containerImage {
  position: relative;
}

.saleSoonTab {
  position: absolute;
  bottom: var(--space-s-neg);
  left: var(--space-s);
  z-index: 3;

  opacity: 1;

  transition: opacity 200ms ease-in-out;
}

.creditTab {
  position: absolute;
  top: var(--space-s);
  left: var(--space-s);
  z-index: 3;

  opacity: 0;

  transition: opacity 200ms ease-in-out;
  &.yandexCreditTab {
    opacity: 1;
  }
}

.extraSaleTag {
  position: absolute;
  bottom: -16px;
  left: var(--space-s);
  z-index: 3;

  opacity: 1;

  transition: opacity 200ms ease-in-out;
}

.preorderTab {
  position: absolute;
  top: var(--space-n);
  left: var(--space-n);
  z-index: 3;

  opacity: 0;

  transition: opacity 200ms ease-in-out;
}

.actions {
  position: absolute;
  top: var(--space-n);
  right: var(--space-n);
  z-index: 3;

  display: grid;
  grid-auto-flow: column;
  grid-gap: var(--space-xs);
}

.solutionTagMobile {
  position: absolute;
  top: var(--space-s);
  left: var(--space-n);
  z-index: 3;

  display: none;
  grid-auto-flow: column;
  grid-gap: var(--space-xs);
}

.penIcon {
  width: 30px;
  height: 30px;

  cursor: pointer;
}

.action {
  cursor: pointer;
}

.fabricsWrapper {
  margin-top: var(--space-n);
}

.info {
  position: relative;
  z-index: 9;

  margin-top: var(--space-xxl);
}

.name {
  display: inline-block;
  max-width: 100%;

  overflow: hidden;

  color: var(--color-black);
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tags {
  position: absolute;
  right: var(--space-s);
  bottom: var(--space-m-neg);
  z-index: 2;
}

.aboutReceipt {
  width: 100%;
  margin-top: var(--space-m);
}

.aboutReceiptText {
  font-size: var(--size-m);
}

.buy {
  margin-top: var(--space-m);
}

.moreWrapper {
  display: flex;
  justify-content: center;
  margin-top: var(--space-m);
}

.more {
  font-size: var(--size-s);
  line-height: 1.5;
}

.sizes {
  margin-top: var(--space-m);

  font-size: var(--size-s);
  line-height: 1.2;
}

.productCard:hover {
  .box,
  .additionalInfo {
    border-color: var(--color-medium-gray);
    visibility: visible;
    opacity: 1;
  }

  .promocodeTag {
    opacity: 0;
  }

  .box {
    border-bottom: 0;
  }

  .additionalInfo {
    border-top: 0;
  }

  .container {
    z-index: 4;
  }

  .tags {
    opacity: 0;
  }

  .saleSoonTab {
    opacity: 0;
  }
}

.comingSoon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: var(--space-xs);
}

.comingSoonButton {
  min-width: 40px;
  height: 40px;
}

.outOfStock {
  color: var(--color-dark-gray);
}

.parameterGroups {
  display: flex;
  flex-direction: column;

  padding-top: var(--space-s);

  &.firstCharacteristics {
    padding-top: 0;
  }
}

.wrapperPrice {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: var(--space-s);
}

.buyMobile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
}

.iconBasket {
  width: 20px;
  height: 20px;
}

.loader {
  width: 20px;
  height: 20px;
}

.readySolutionsContainer {
  display: flex;
  gap: var(--space-xs);
  align-items: center;
  margin-top: -5px;
}

.readySolutionsContainerSpace {
  gap: var(--space-s);
}

.solutionTagDesktop {
  margin-top: var(--space-n);
}

.solutionTagDesktopWithoutColor {
  margin-top: var(--space-s);
}

@media (--desktop-m) {
  .hasExtraDiscount {
    .tags {
      visibility: hidden;
    }
  }
}

@media (--desktop) {
  .box,
  .additionalInfo {
    right: var(--space-s-neg);
    left: var(--space-s-neg);
  }

  .box {
    top: var(--space-n-neg);
  }

  .additionalInfo {
    padding: 0 var(--space-s) var(--space-n);
  }
}

@media (--mobile-m) {
  .hasExtraDiscount {
    .tags {
      visibility: visible;
    }
  }

  .productCard {
    .box,
    .additionalInfo {
      display: none;
    }

    &.viewMini {
      .fabricsWrapper {
        margin-top: var(--space-xs);
      }
    }

    &:hover {
      .saleSoonTab {
        opacity: 1;
      }
      .promocodeTag {
        opacity: 1;
      }
    }
  }

  .name {
    display: block;
  }

  .preorderTab {
    position: static;

    margin-top: var(--space-xs);

    opacity: 1;
  }

  .sizes {
    display: none;
  }

  .extraSaleTag {
    position: static;

    width: max-content;

    margin-top: var(--space-xs);

    opacity: 1;
  }

  .saleSoonTab {
    position: static;

    margin-top: var(--space-xs);
  }

  .creditTab {
    position: static;

    width: max-content;

    margin-top: var(--space-xs);

    opacity: 1;
  }

  .wrapperPrice {
    margin-top: var(--space-xs);
    margin-right: var(--space-s);
  }

  .solutionTagMobile {
    display: grid;
  }

  .readySolutionsContainer {
    display: block;
    margin-top: 0;
  }

  .solutionTagDesktop {
    display: none;
  }

  .solutionTagDesktopWithoutColor {
    margin-top: 0;
  }
}

.characteristicsWrapper {
  opacity: 1;
}

@media (--mobile) {
  .extraSaleTag {
    margin-top: var(--space-xs);
  }

  .creditTab {
    margin-top: var(--space-xs);
  }

  .actions {
    top: var(--space-xxs);
    right: var(--space-xxs);

    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
  }

  .action {
    width: 20px;
    height: 20px;
  }

  .productCard {
    .tags {
      display: none;
    }

    .info {
      margin-top: var(--space-s);
      padding: 0 var(--space-s);
    }

    &.viewMini {
      .parameterGroups {
        display: none;
      }

      .info {
        margin-top: var(--space-xs);
        padding: 0 var(--space-s);
      }

      .name {
        display: -webkit-box;
        height: calc(var(--size-s) * 1.5 * 2);

        font-size: var(--size-s);
        line-height: 1.5;
        white-space: normal;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

  .wrapperPrice {
    margin-right: 0;
  }

  .solutionTagMobile {
    top: var(--space-xxs);
    left: var(--space-xxs);
  }

  .penIcon {
    width: 22px;
    height: 22px;

    background: transparent;
  }
}

@media (--mobile-xxs) {
  .productCard {
    &.viewMini {
      .info {
        padding: 0 var(--space-xxs);
      }
    }
  }

  .uzb {
    .buyMobile {
      width: 30px;
      min-width: 30px;
      height: 30px;
    }

    .iconBasket {
      width: 15px;
      height: 15px;
    }
  }
}

@media (min-width: 1024px) {
  .productCard:hover {
    .preorderTab {
      opacity: 1;
    }

    .creditTab {
      opacity: 1;
    }

    .extraSaleTag.withCredit {
      opacity: 0;
    }
  }
}
