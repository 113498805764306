.fabrics {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.fabrics.sizeXS {
  row-gap: 4px;

  .fabric {
    margin-right: 4px;

    &:last-child {
      margin-right: 0;
    }

    .fabricContainer {
      width: 14px;
      height: 14px;
    }

    &:not(.disabled, .selected) .fabricContainer:hover .imageWrapper {
      border-color: var(--color-light-gray);
      box-shadow: 0 0 0 1px var(--color-white), 0 0 0 2px var(--color-medium-gray);
    }

    &.selected .imageWrapper {
      border-color: var(--color-light-gray);
      box-shadow: 0 0 0 1px var(--color-white), 0 0 0 2px var(--color-dark-gray);
    }
  }
}

.fabrics.sizeS {
  row-gap: var(--space-xs);

  .fabric {
    margin-right: var(--space-xs);

    &:last-child {
      margin-right: 0;
    }

    .fabricContainer {
      width: 16px;
      height: 16px;
    }

    &:not(.disabled, .selected) .fabricContainer:hover .imageWrapper {
      border-color: var(--color-light-gray);
      box-shadow: 0 0 0 2px var(--color-white), 0 0 0 3px var(--color-medium-gray);
    }

    &.selected .imageWrapper {
      border-color: var(--color-light-gray);
      box-shadow: 0 0 0 2px var(--color-white), 0 0 0 3px var(--color-dark-gray);
    }
  }
}

.fabrics.sizeM {
  row-gap: var(--space-n);

  .fabric {
    margin-right: var(--space-n);

    &:last-child {
      margin-right: 0;
    }

    .fabricContainer {
      width: 25px;
      height: 25px;
    }

    &:not(.disabled, .selected) .fabricContainer:hover .imageWrapper {
      border-color: var(--color-light-gray);
      box-shadow: 0 0 0 4px var(--color-white), 0 0 0 5px var(--color-medium-gray);
    }

    &.selected .imageWrapper {
      border-color: var(--color-light-gray);
      box-shadow: 0 0 0 4px var(--color-white), 0 0 0 5px var(--color-dark-gray);
    }
  }
}

.sizeL {
  row-gap: var(--space-n);

  .fabric {
    margin-right: var(--space-n);

    &:last-child {
      margin-right: 0;
    }

    .fabricContainer {
      width: 40px;
      height: 40px;
    }

    &:not(.disabled, .selected) .fabricContainer:hover .imageWrapper {
      border-color: var(--color-light-gray);
      box-shadow: 0 0 0 4px var(--color-white), 0 0 0 5px var(--color-medium-gray);
    }

    &.selected .imageWrapper {
      border-color: var(--color-light-gray);
      box-shadow: 0 0 0 4px var(--color-white), 0 0 0 5px var(--color-dark-gray);
    }
  }
}

.fabricContainer {
  display: block;
}

.imageWrapper {
  position: relative;
  z-index: 2;

  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  overflow: hidden;

  background-size: cover;
  border: 1px solid var(--color-medium-gray);
  border-radius: 50%;

  transition: box-shadow 0.2s ease-in-out, border 0.2s ease-in-out;
}

.fabric {
  display: flex;
  align-items: center;

  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.counter {
  margin-left: var(--space-s);

  color: var(--color-dark-gray);
  font-size: var(--size-s);
  line-height: 1.5;
  white-space: nowrap;

  cursor: pointer;

  transition: color 0.2s ease-in-out;

  &:hover {
    color: var(--color-black);
  }
}

.image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;

  transform: scale(1.2);
}

.disabled {
  pointer-events: none;
}
